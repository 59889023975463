var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"ma-5"},[_c('v-breadcrumbs',{staticClass:"pl-0",attrs:{"items":_vm.breadCrumbs,"large":""}}),_c('p',{staticClass:"text-h5 secondary--text my-4"},[_vm._v(_vm._s(_vm.modalidadData?.nombre))]),_c('v-row',{staticClass:"my-5"},[_c('v-col',{attrs:{"cols":"12","sm":"3","md":"2"}},[_c('v-btn',{attrs:{"block":"","color":"secondary","to":{
          name: 'configuracion-hito',
          params: { idModalidad: _vm.modalidadData.id },
        },"dark":""}},[_vm._v(" Agregar hito ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-autocomplete',{attrs:{"dense":"","outlined":"","items":[{ id: true, nombre: 'Público' }, { id: false, nombre: 'Privado' }],"item-value":"id","item-text":"nombre"},on:{"change":function($event){return _vm.updateModalidad()}},model:{value:(_vm.tipoPrivacidadProceso),callback:function ($$v) {_vm.tipoPrivacidadProceso=$$v},expression:"tipoPrivacidadProceso"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.encabezado,"no-data-text":"No hay datos","items":_vm.etapas,"items-per-page":_vm.etapas.length,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.cantidad`,fn:function({ item }){return [_c('v-select',{staticClass:"my-1",attrs:{"outlined":"","dense":"","hide-details":"","items":_vm.suma},on:{"change":function($event){return _vm.cambiarOrden(item)}},model:{value:(item.orden),callback:function ($$v) {_vm.$set(item, "orden", $$v)},expression:"item.orden"}})]}},{key:`item.acciones`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"right":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":{
              name: 'configuracion-hito',
              params: { idModalidad: _vm.modalidadData.id, idHito: item.id },
            }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c('span',[_vm._v(" Configurar etapa")])]),_c('v-tooltip',{attrs:{"right":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.eliminar(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(" Eliminar hito")])])]}}],null,true)}),_c('div',{staticClass:"mt-9"},[_c('v-btn',{attrs:{"color":"secondary","outlined":"","to":{ name: 'configuracion-proceso-compra' }}},[_vm._v("Volver")])],1),_c('v-dialog',{attrs:{"max-width":"30vw"},on:{"click:outside":function($event){_vm.agregarModal = false}},model:{value:(_vm.agregarModal),callback:function ($$v) {_vm.agregarModal=$$v},expression:"agregarModal"}},[_c('v-card',{attrs:{"height":"265"}},[_c('v-card-title',{staticClass:"my-3"},[_vm._v("Agregar un hito")]),_c('v-card-text',{staticClass:"py-0"},[_c('v-row',{staticClass:"pb-0"},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"label":"Nombre del hito","outlined":""}})],1)],1)],1),_c('v-card-actions',{staticClass:"py-0"},[_c('v-row',{staticClass:"ma-2"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.agregarModal = false}}},[_vm._v("Volver")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":"secondary","dark":""}},[_vm._v("Agregar")])],1)],1)],1)],1)],1),_c('ConfirmationDialogComponent',{attrs:{"show":_vm.eliminarEtapa,"title":"¿Desea eliminar este hito?","message":"De confirmarse la siguiente acción, los cambios no serán reversibles.","btnConfirmar":"Aceptar"},on:{"close":function($event){_vm.eliminarEtapa = false},"confirm":_vm.deleteHito}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }