import Sortable from 'sortablejs';
<template>
  <section class="ma-5">
    <v-breadcrumbs class="pl-0" :items="breadCrumbs" large></v-breadcrumbs>

    <p class="text-h5 secondary--text my-4">{{ modalidadData?.nombre }}</p>

    <v-row class="my-5">
      <v-col cols="12" sm="3" md="2">
        <v-btn
          block
          color="secondary"
          :to="{
            name: 'configuracion-hito',
            params: { idModalidad: modalidadData.id },
          }"
          dark
        >
          Agregar hito
        </v-btn>
      </v-col>
      <v-col cols="12" sm="3" md="3">
        <v-autocomplete
          dense
          outlined
          v-model="tipoPrivacidadProceso"
          :items="[{ id: true, nombre: 'Público' }, { id: false, nombre: 'Privado' }]"
          item-value="id"
          item-text="nombre"
          @change="updateModalidad()"
        />
      </v-col>
    </v-row>

    <v-data-table
      :headers="encabezado"
      no-data-text="No hay datos"
      :items="etapas"
      :items-per-page="etapas.length"
      hide-default-footer
    >
      <template #[`item.cantidad`]="{ item }">
        <v-select
          v-model="item.orden"
          outlined
          dense
          hide-details
          class="my-1"
          :items="suma"
          @change="cambiarOrden(item)"
        ></v-select>
      </template>
      <template #[`item.acciones`]="{ item }">
        <v-tooltip right left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              :to="{
                name: 'configuracion-hito',
                params: { idModalidad: modalidadData.id, idHito: item.id },
              }"
            >
              <v-icon color="secondary">mdi-cog</v-icon>
            </v-btn>
          </template>
          <span> Configurar etapa</span>
        </v-tooltip>
        <v-tooltip right left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              @click="eliminar(item)"
            >
              <v-icon color="secondary">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span> Eliminar hito</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <div class="mt-9">
      <v-btn
        color="secondary"
        outlined
        :to="{ name: 'configuracion-proceso-compra' }"
        >Volver</v-btn
      >
    </div>

    <v-dialog
      v-model="agregarModal"
      max-width="30vw"
      @click:outside="agregarModal = false"
    >
      <v-card height="265">
        <v-card-title class="my-3">Agregar un hito</v-card-title>
        <v-card-text class="py-0">
          <v-row class="pb-0">
            <v-col cols="10">
              <v-text-field label="Nombre del hito" outlined></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="py-0">
          <v-row class="ma-2">
            <v-col cols="3">
              <v-btn color="secondary" outlined @click="agregarModal = false"
                >Volver</v-btn
              >
            </v-col>
            <v-col cols="3">
              <v-btn color="secondary" dark>Agregar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmationDialogComponent 
     :show="eliminarEtapa"
     title="¿Desea eliminar este hito?"
     message="De confirmarse la siguiente acción, los cambios no serán reversibles."
     btnConfirmar="Aceptar"
     @close="eliminarEtapa = false"
     @confirm="deleteHito"
    />
  </section>
</template>

<script>
import ConfirmationDialogComponent from '../../components/ConfirmationDialogComponent.vue';
export default {
  components:{ConfirmationDialogComponent},
  data: () => ({
    encabezado: [
      {
        text: "N°",
        value: "cantidad",
        align: "start",
        width: "150px",
      },
      {
        text: "Hito",
        value: "nombre",
        align: "start",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    etapas: [{ cantidad: 1, nombre_etapa: "Solicitud de contratación" }],
    agregarModal: false,
    modalidadData: {},
    id:null,
    eliminarEtapa:false,
    tipoPrivacidadProceso: null,
  }),
  computed: {
    breadCrumbs() {
      return [
        {
          text: "Modalidad",
          disabled: false,
          href: "/configuracion-proceso-compra",
        },
        {
          text: this.modalidadData?.nombre,
          disabled: false,
          href: "#",
        },
      ];
    },
    suma() {
      let cantidadR = this.rangeElements(this.etapas.length);
      return cantidadR;
    },
  },
  methods: {
    abrirModal() {
      this.agregarModal = true;
    },
    rangeElements(size, startAt = 1) {
      return [...Array(size).keys()].map((i) => i + startAt );
    },
    async getModalidad() {

      const { status, data } = await this.services.PacProcesos.getModalidad(
        this.$route.params.id_modalidad
      );

      if (status == 200) {
        this.etapas = data?.FormaContratacionEtapas;
        this.modalidadData = data;
        this.tipoPrivacidadProceso = this.modalidadData.participacion_publica;
      }

    },
    async cambiarOrden(item) {

      const { status, data } = await this.services.PacProcesos.cambiarOrdenHitos(item.id, {
        orden: item.orden,
      });

      if (status === 200) {
        await this.getModalidad();
      }

    },
    eliminar(item){
      this.id = item.id
      this.eliminarEtapa = true
    },
    async deleteHito(){

      const res = await this.services.PacProcesos.eliminarHito(this.id)

      if(res){
        this.temporalAlert({
          show:true,
          type: "success",
          message: "Hito eliminado con éxito"
        })
        this.getModalidad()
      }

      this.eliminarEtapa = false
    },
    async updateModalidad() {

      await this.services.PacProcesos.putModalidad(this.$route.params.id_modalidad, {
        participacion_publica: this.tipoPrivacidadProceso,
      })

    }
  },
  created() {
    this.getModalidad();
  },
};
</script>

<style></style>
